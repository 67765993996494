@import url('https://fonts.googleapis.com/css2?family=Anton&family=Babylonica&family=Bodoni+Moda:opsz,wght@6..96,400;6..96,500;6..96,700&family=Calligraffitti&family=Cinzel&family=EB+Garamond:ital,wght@0,400;0,600;1,400&family=Fauna+One&family=Henny+Penny&family=Lato:wght@100;300;400&family=League+Spartan:wght@200;300;400;500&family=Libre+Baskerville:wght@400;700&family=Montserrat:wght@100;200;300;400&family=Nunito:wght@200;400&family=Poppins:wght@100;200;300&display=swap');

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
 
  --clr-primary-1: #4296E6;
  --clr-primary-2: #FCFEFF;
  --clr-primary-3: #545454;
  --clr-primary-4: #dee2e6;
  --clr-primary-8: #ECF6FF;
  --clr-primary-9: #F8F9FD;

  --clr-primary-hover:#53AFFF;
  --clr-primary-10: #3A4E65 ;
    --clr-primary-11: #fff;
  --clr-body: #12121C;
  --clr-white: #fff;
  
  --clr-black: #222;
    --primary-font1: 'Lato', sans-serif;
  --primary-font2: 'Merriweather', serif;
  --primary-font3: 'Montserrat' sans-serif;
  --primary-font4: 'Oswald', sans-serif;
  --primary-font5: 'Playfair Display', serif;
  --primary-font6: 'Raleway', sans-serif;
  --primary-font7: 'Rubik Microbe', cursive;
  --primary-font8: 'Rubik Moonrocks', cursive;
  --primary-font9: 'Anton', sans-serif;
  --primary-font10: 'Babylonica', cursive;
  --primary-font11: 'Bodoni Moda', serif;
  --primary-font12:  'Calligraffitti', cursive;
  --primary-font13: 'EB Garamond', serif;
  --primary-font14:'Henny Penny', cursive;
  --primary-font15:'League Spartan', sans-serif;
  --primary-font16:'Libre Baskerville', serif;
  --primary-font17:'Nunito', sans-serif;
  --primary-font18:'Poppins', sans-serif;
  --primary-font19: 'Cinzel', serif;
  --primary-font20: 'Fauna One', serif;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
  
}



body {
  background:var(--clr-primary-11);
  color: var(--clr-black);
  transition: var(--darkModeTransition);
  font-family: var(--primary-font3);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
.btn {
  margin: 1rem auto;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--spacing);
  background-color: var(--clr-primary-hover);
  color: var(--clr-white);
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.2s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.btn:hover {
  background-color: var(--clr-primary-hover);
  color: var(--clr-black);
  transform: translateY(-2px);
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--clr-primary-hover);
}

.btn:active {
  transform: translateY(1px);
}

/*  global classes */

/* section */
.section-center {
  width: 90vw;
  margin: 5rem auto;
  max-width: var(--max-width);

}
.nav-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);

}

.title h3{
  text-transform:uppercase;
  font-size: 1.5rem;
  font-weight: 800;
  color: var(--clr-white);
}
.underline{
  width: 10rem;
  height: 1rem;
  background: var(--clr-primary-8);
  margin: 0 auto;
}
/* SnowEffect */
canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11; /* Send the canvas behind other content */
}
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full height */
  background-color: #f0f0f0; /* Change this to your preferred background color */
}

h3 {
  margin-bottom: 20px;
}

.circle {
  position: relative;
  width: 200px; /* Set the size of the circle */
  height: 200px;
  border-radius: 50%;
  
  overflow: hidden; /* Hides overflow */
}

.circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
  font-size: 16px; /* Adjust size as needed */
  color: #333; /* Text color */
  z-index: 1; /* Bring text above the circle */
}

.circle-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid var(--clr-primary-hover); /* Color of the progress */
  border-top-color: transparent; /* Hide the top */
  transition: transform 0.5s linear; /* Smooth transition */
}


/* navbar */
/* General styles for the navbar */
.navbar {
  background-color: var(--clr-primary-1); /* Primary color */
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  box-shadow: var(--light-shadow); /* Subtle shadow */
  font-family: var(--primary-font1); /* Lato */
  transition: var(--transition);
}

.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.menu-icon {
  display: block;
  color: var(--clr-primary-2); /* Light color */
  font-size: 30px;
  cursor: pointer;
  display: none;
  transition: var(--transition);
}

.nav-menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  margin: 0 15px;
}

.nav-item a {
  color: var(--clr-primary-2); /* Light color */
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  border-radius: var(--radius); /* Rounded corners */
  cursor: pointer; /* Add cursor pointer */
  transition: var(--transition);
  text-transform: capitalize;
}

.nav-item a:hover {
  color: var(--clr-primary-1); /* Change to primary color on hover */
  background-color: var(--clr-primary-8); /* Add background color on hover */
  transform: translateY(-3px); /* Slight upward movement for hover effect */
}

.nav-item a:active {
  transform: translateY(0); /* Reset transform on click */
}

.image-wrapper {
  display: flex;
  align-items: center;
}

.image-wrapper img {
  height: 40px;
}

.btn-nav {
  margin-left: 10px;
  color: var(--clr-primary-2); /* Light color */
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}

.btn-nav:hover {
  color: var(--clr-primary-hover); /* Hover effect on button */
}

/* Mobile view (under 768px) */
@media screen and (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    background-color: var(--clr-primary-1); /* Same as navbar background */
    width: 100%;
    transition: transform 0.3s ease-in-out;
  }

  .nav-menu.active {
    display: flex;
    transform: translateY(0);
  }

  .nav-item {
    text-align: center;
    margin: 20px 0;
  }

  .nav-item a {
    font-size: 22px;
    font-weight: 600;
  }

  .image-wrapper {
    margin-top: 20px;
    text-align: center;
  }

  .btn-nav {
    display: none; /* Hide the "Get in Touch" button on mobile */
  }
}

/* Desktop view (above 768px) */
@media screen and (min-width: 769px) {
  .nav-menu {
    display: flex;
    flex-direction: row;
  }

  .menu-icon {
    display: none; /* Hide menu icon on desktop */
  }
}


/* Keyframes for animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Hero Section */
.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fff;
  position: relative;
  padding: 50px 20px;
  overflow: hidden;
  text-align: center;
  color: #333;
  animation: scaleUp 1s ease-in-out;
}

/* Background Image */
.hero-first-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.hero-first-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
  filter: blur(3px);
}

/* Hero Container */
.hero-container {
  z-index: 2;
  position: relative;
  color: #333;
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
}

/* Fade-in effect for text */
.hero h3, .hero h1, .hero h2, .hero p {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

.hero h3 {
  font-size: 2rem;
  color: #333;
  font-weight: 400;
  margin-bottom: 0.5rem;
  animation-delay: 0.2s;
}

.hero h1 {
  font-size: 3.5rem;
  color: #4296e6;
  font-weight: 700;
  margin-bottom: 0.5rem;
  animation-delay: 0.4s;
}

.hero h2 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #777;
  margin-top: 1rem;
  animation-delay: 0.6s;
}

.hero h2 .span {
  color: #4296e6;
}

.hero p {
  font-size: 1.1rem;
  color: #777;
  margin-top: 20px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  animation-delay: 0.8s;
}

/* Buttons */
.btn-hero-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.btn-hero-work,
.btn-hero-hired {
  background-color: #4296e6;
  color: #fff;
  font-size: 1rem;
  padding: 15px 30px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  letter-spacing: 1px;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 1s;
}

.btn-hero-work:hover,
.btn-hero-hired:hover {
  background-color: #53afff;
  transform: translateY(-4px);
  box-shadow: 0px 5px 20px rgba(66, 150, 230, 0.3);
}

.btn-hero-work a,
.btn-hero-hired a {
  color: inherit;
  text-decoration: none;
}

/* Profile Image with floating effect */
.hero-profile {
  position: relative;
  margin-top: 30px;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 5px solid #fff;
  padding: 10px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  z-index: 2;
  animation: float 4s ease-in-out infinite;
}

.hero-profile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.hero-profile:hover {
  transform: translateY(-10px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .hero {
    padding: 60px 20px;
  }

  .hero h3 {
    font-size: 2.2rem;
  }

  .hero h1 {
    font-size: 3.2rem;
  }

  .hero p {
    font-size: 1.1rem;
  }

  .btn-hero-container {
    margin-top: 50px;
  }

  .btn-hero-work,
  .btn-hero-hired {
    font-size: 1rem;
    padding: 14px 28px;
  }
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 50px 15px;
  }

  .hero h3 {
    font-size: 1.8rem;
  }

  .hero h1 {
    font-size: 2.8rem;
  }

  .hero h2 {
    font-size: 1.3rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .btn-hero-container {
    margin-top: 30px;
    gap: 15px;
  }

  .btn-hero-work,
  .btn-hero-hired {
    font-size: 0.9rem;
    padding: 12px 24px;
  }

  .hero-profile {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: 40px 10px;
  }

  .hero h3 {
    font-size: 1.5rem;
  }

  .hero h1 {
    font-size: 2.4rem;
  }

  .hero h2 {
    font-size: 1.2rem;
  }

  .hero p {
    font-size: 0.9rem;
  }

  .btn-hero-container {
    flex-direction: column;
    gap: 10px;
  }

  .btn-hero-work,
  .btn-hero-hired {
    width: 100%;
    font-size: 0.85rem;
    padding: 10px 20px;
  }

  .hero-profile {
    width: 130px;
    height: 130px;
  }
}

/* About Section */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 80px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.section-center {
  max-width: 900px;
  margin: 0 auto;
}

.title-about h3 {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  position: relative;
}

.title-about h3::after {
  content: "";
  display: block;
  width: 80px;
  height: 4px;
  background: #4296e6;
  margin: 10px auto;
  border-radius: 2px;
}

.about-first-text,
.about-second-text {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.8;
  margin-bottom: 20px;
  padding: 0 20px;
  max-width: 800px;
  text-align: justify;
}

.btn-hero-hired {
  display: inline-block;
  background: #4296e6;
  color: #fff;
  font-size: 1rem;
  padding: 14px 28px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
}

.btn-hero-hired:hover {
  background: #53afff;
  transform: translateY(-4px);
}

/* Images */
.first-image,
.second-image {
  position: absolute;
  width: 200px; /* Increased size */
  height: 200px;
  opacity: 0.9;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.first-image img,
.second-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Floating Animation */
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.first-image {
  top: 15%;
  left: 10%;
  animation: floatAnimation 5s infinite ease-in-out;
}

.second-image {
  bottom: 15%;
  right: 10%;
  animation: floatAnimation 5s infinite ease-in-out alternate;
}

/* Hover Animation */
.first-image:hover,
.second-image:hover {
  transform: scale(1.1);
  opacity: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about {
    padding: 60px 20px;
  }

  .title-about h3 {
    font-size: 1.8rem;
  }

  .about-first-text,
  .about-second-text {
    font-size: 1rem;
    padding: 0 10px;
  }

  .btn-hero-hired {
    font-size: 0.9rem;
    padding: 12px 24px;
  }

  .first-image,
  .second-image {
    width: 150px;
    height: 150px;
  }

  .first-image {
    top: 10%;
    left: 5%;
  }

  .second-image {
    bottom: 10%;
    right: 5%;
  }
}

/* Projects */
.container-projects {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 2rem;
}

.projects {
  padding: 4rem 0;
}

.section-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.projrect-title h3 {
  font-size: 2rem;
  font-family: var(--primary-font9);
  text-align: center;
  color: var(--clr-primary-3);
}

.projects-img {
  max-width: 100%;
  height: auto;
}

.projects-info {
  background: var(--clr-primary-9);
  padding: 2rem;
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
  transition: all 0.3s ease;
}

.projects-info h3 {
  font-size: 1.5rem;
  font-family: var(--primary-font3);
  margin-bottom: 1rem;
  color: var(--clr-primary-3);
}

.projects-info p {
  font-family: var(--primary-font1);
  color: var(--clr-primary-3);
  margin-bottom: 1.5rem;
}

.projects-languages {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.projects-languages span {
  font-size: 0.875rem;
  background: var(--clr-primary-4);
  padding: 0.5rem 1rem;
  border-radius: var(--radius);
  color: var(--clr-primary-3);
}

.project-details p {
  font-family: var(--primary-font1);
  color: var(--clr-primary-3);
  margin-top: 1rem;
}

.projects-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}
.projects-img img {
  width: 100%;
  height: 300px; /* You can adjust this to the size you want */
  object-fit: cover; /* This will crop the images to fit the defined size */
  border-radius: var(--radius);
}

.view-project-btn,
.github-btn {
  padding: 0.75rem 1.5rem;
  background-color: var(--clr-primary-1);
  color: var(--clr-white);
  border: none;
  border-radius: var(--radius);
  cursor: pointer;
  font-family: var(--primary-font3);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: background-color 0.3s ease;
}

.view-project-btn:hover,
.github-btn:hover {
  background-color: var(--clr-primary-hover);
}

.github-btn a,.view-project-btn a {
  color: var(--clr-white);
  text-decoration: none;
}

.github-btn a,.view-project-btn a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .section-center {
    padding: 1rem;
  }

  .projects-info {
    padding: 1rem;
  }

  .projects-img {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .projects-languages {
    justify-content: center;
  }

  .projects-info h3 {
    font-size: 1.25rem;
  }

  .project-details p {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .projrect-title h3 {
    font-size: 1.5rem;
  }

  .projects-info h3 {
    font-size: 1.125rem;
  }

  .view-project-btn,
  .github-btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  .projects-languages span {
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
  }

  .project-details p {
    font-size: 0.75rem;
  }
}
/* Skills */
.skills {
  position: relative;
  padding: 2rem;
  background-color: var(--clr-primary-2);
}



.skills-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}


.skills-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; 
  gap: 2rem;
  width: 100%;
  margin-top: 2rem;
}


.column {
  display: flex;
  align-items: center;
  gap: 10px;
}
.column span{
  margin-right: 1rem;
}

.skills-container h3 {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  background-color: var(--clr-primary-8);
  padding: 0.5rem;
  border-radius: var(--radius);
}

.skills-container span {
  font-size: 1.5rem;
  color: #61dafb; 
}


.skill-profile img {
  width: 220px; 
  border-radius: 50%;
  margin-top: 2rem;
}
.skills-years span{
  color: var(--clr-primary-1);
}
.first-img-skills,
.second-img-skills {
  display: none; 
}

.first-img-skills img,
.second-img-skills img {
  height: 100%; 
  width: 100%;
  object-fit: cover; 
}

@media (min-width: 992px) {
  
  .first-img-skills {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 40%; 
    height: 100%; 
  }

  .second-img-skills {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 40%; 
    height: 100%; 
  }

  .skills-container {
    position: relative;
    z-index: 2; 
  }
}

@media (max-width: 991px) {
  
  .skills-container {
    grid-template-columns: 1fr 1fr; 
  }

  
  .skill-profile img {
    width: 200px;
  }
}

@media (max-width: 767px) {
  
  .skills-container {
    grid-template-columns: 1fr; 
  }

  .skills-container h3 {
    font-size: 1rem; 
  }

  .first-img-skills,
  .second-img-skills {
    display: none; 
  }

 
  .skill-profile img {
    width: 250px; 
    margin-top: 2rem;
  }
}
/* ContactPage */
/* Contact Section */
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;
  background-color: #121212;
  color: #ffffff;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  gap: 40px;
}

.contact-details {
  text-align: center;
}

.contact-details h3 {
  font-size: 1.2rem;
  color: var(--clr-white);
}

.contact-details h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Service Offerings */
.contact-offer {
  background: rgba(255, 255, 255, 0.1);
  padding: 15px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.contact-offer h3 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
}

.contact-offer span {
  font-weight: bold;
  color:var(--clr-primary-1);
}

/* Contact Form */
.contact-form {
  background-color: #1e1e1e;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 500px;
}

.form-contact {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-info h3 {
  font-size: 1rem;
  margin-bottom: 8px;
  color: #bbb;
}

input, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #444;
  border-radius: 8px;
  background: #2a2a2a;
  color: white;
  font-size: 1rem;
  transition: 0.3s;
}

input:focus, textarea:focus {
  border-color: var(--clr-primary-1);
  outline: none;
}

textarea {
  resize: none;
}

.btn {
  background: var(--clr-primary-1);
  color: white;
  border: none;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.btn:hover {
  background: var(--clr-primary-hover);
}

/* Success and Error Messages */
.success-message, .error-message {
  text-align: center;
  font-size: 1rem;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
}

.success-message {
  color: #4caf50;
  background: rgba(76, 175, 80, 0.1);
}

.error-message {
  color: #ff4d4d;
  background: rgba(255, 77, 77, 0.1);
}

/* Responsive Design */
@media (min-width: 768px) {
  .contact-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .contact-details {
    text-align: left;
    max-width: 500px;
  }

  .contact-form {
    width: 500px;
  }
}

 /* stacks */
 .stacks {
  box-shadow: var(--light-shadow);
  border-radius: var(--radius);
  text-align: center;
  margin: 50px auto;
}

.stacks h3 {
  font-size: 1.5rem;
  color: #555;
}

.stacks h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: var(--clr-primary-1);
}

.stcks-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.icon {
  text-align: center;
  width: 80px;
  font-size: 1.2rem;
}

.icon p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color:var(--clr-white);
}

.icon svg {
  transition: transform 0.3s ease;
}

.icon:hover svg {
  transform: scale(1.1); /* Add hover effect */
}
/* Footer */
/* Footer styles */
.footer {
  background: url("./assets/footer.png") center/cover no-repeat;
  color: var(--clr-black);
  padding: 2rem 0;
  text-align: center;
}



.footer-column h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--clr-primary-1);
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column li {
  font-size: 1rem;
  margin: 0.5rem 0;
  color: var(--clr-grey-3);
}

.footer-column li a {
  color: var(--clr-grey-3);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-column li a:hover {
  color: var(--clr-white);
}

.footer-bottom {
  border-top: 1px solid var(--clr-grey-3);
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.footer-bottom p {
  font-size: 1rem;
  color: var(--clr-grey-3);
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icons a {
  color: var(--clr-grey-3);
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
  color: var(--clr-primary-1);
  transform: scale(1.1);
}

.testimonials h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: var(--clr-primary-1);
}

.testimonial-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.testimonial {
  background-color: var(--clr-white);
  padding: 1.5rem;
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
  text-align: left;
}

.testimonial .review {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: var(--clr-dark-gray);
}

.testimonial .client-name {
  font-weight: bold;
  color: var(--clr-primary-1);
  margin-bottom: 0.5rem;
}

.testimonial .client-role {
  font-size: 0.875rem;
  color: var(--clr-gray);
}
@media (min-width:992px) {
  .footer-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}
}

/* MoreAboutme */
.more-about-me {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.more-about-me h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.more-about-me p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.more-about-me button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.more-about-me button:hover {
  background-color: #0056b3;
}
/* Services */
.services {
  padding: 40px;
  background-color: #f4f4f4;
  text-align: center;
}

.services h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.service {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.service h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.service p {
  font-size: 1rem;
  color: #666;
}
